import { useLanguage } from "../../languageContext";

export default function Footer() {

  const {translations,language} = useLanguage();

    return (
      <footer dir={language === 'ar' ? 'rtl' : 'ltr'} className="bg-gray-semigray text-gray-black py-2 pt-6 px-4 sm:px-[5em]">
        <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start sm:gap-8">
          <div className="flex gap-3 mt-3 items-center w-full sm:w-auto">
            <div className="border-r-2 border-gray-dark w-[7em] p-3">
              <img
                src="/assets/logonew.png"
                className="w-full object-contain"
                alt="icon ecc logo"
              />
            </div>
            <div className="py-2">
              <address dir={language === 'ar' ? 'rtl' : 'ltr'} className={`${language === 'ar' ? 'text-right':'text-left'} font-normal flex flex-col items-left mb-2 text-sm sm:text-base`}>
                {translations['SJK Compound, Opp Jerome Villa']}, <br />
                {translations['Mohili Village, Sakinaka']}, <br />
                {translations['Mumbai']}, 400 072.
                <a href="tel:9656039896" className="block mt-1">
                  {translations['Tel']} : 9656039896
                </a>
              </address>
              <a href="mailto:infodesk@iconecc.co.in" className="block mt-1">
                infodesk@iconecc.co.in
              </a>
            </div>
          </div>
  
          {/* Right Section (Links) */}
          <div className="flex gap-3 mt-3 sm:mt-0 text-sm sm:text-base">
            <div>{translations.aboutUs}</div>
            <div>{translations['Privacy Policy']}</div>
            <div>{translations.ContactUs}</div>
          </div>
        </div>
  
        {/* Footer Copyright */}
        <div className="text-center mt-6">
          <p className="text-sm sm:text-base">
            <small dir={language === 'ar' ? 'ltr' : 'rtl'}>© 2024 {translations['i-CON Engineering Construction and Consultants']}</small>
          </p>
        </div>
      </footer>
    );
  }
  