import { useState } from "react";
import { List, X } from "@phosphor-icons/react"; // Phosphor icons for the hamburger menu
import { useLanguage } from "../../languageContext";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {language,translations,} = useLanguage();

  const navigate = useNavigate();

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <nav
      dir={language === "ar" ? "rtl" : "ltr"}
      id="navbar"
      className="fixed top-0 left-0 right-0 z-50 bg-white shadow-sm text-black"
    >
      <div className="flex items-start justify-between px-4 py-4">
        {/* Logo */}
        

        {/* Hamburger Icon */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-black focus:outline-none"
          aria-label="Toggle Menu"
        >
          {isMenuOpen ? <X size={28} /> : <List size={28} />}
        </button>

        {/* Links */}
        <ul
          className={`md:flex md:space-x-4 md:items-center ${
            isMenuOpen ? "flex flex-col items-center mt-4 space-y-4" : "hidden"
          } md:mt-0 md:space-y-0`}
        >
          <li>
            <a href="/home" className="hover:underline hover:font-bold">
              {translations.home}
            </a>
          </li>
          <li>
            <a href="/about" className="hover:underline hover:font-bold">
            {translations.aboutUs}
            </a>
          </li>
          <li>
            <a href="/projects" className="hover:underline hover:font-bold">
              {translations.whatWeBuilt}
            </a>
          </li>
          <li>
            <a href="/services" className="hover:underline hover:font-bold">
            {translations.whatWeProvide}
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:underline hover:font-bold">
              {translations.connectToUs}
            </a>
          </li>
          <li>
            <a href="/careers" className="hover:underline hover:font-bold">
              {translations.careers}
            </a>
          </li>
        </ul>
        <div onClick={()=>navigate('/')} className="text-xl font-bold flex items-center gap-2">
          <img
            src="/logo512.png"
            alt="Icon ECC Logo"
            className="h-[2em] object-contain rounded-sm p-1"
          />
        </div>
      </div>
    </nav>
  );
}
