import React, { createContext, useState, useEffect, useContext } from 'react';

// Create Context
const LanguageContext = createContext();

// Language Provider Component
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // Fetch language from localStorage or default to 'en'
    return localStorage.getItem('language') || 'en';
  });
  const [translations, setTranslations] = useState({});

  // Fetch translations based on the selected language
  useEffect(() => {
    const loadTranslations = async () => {
      const response = await fetch(`/locales/${language}/translation.json`);
      const data = await response.json();
      setTranslations(data);
    };

    loadTranslations();
  }, [language]);

  // Function to switch language
  const switchLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang); // Persist language in localStorage
  };

  return (
    <LanguageContext.Provider value={{ translations, language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use LanguageContext
export const useLanguage = () => {
  return useContext(LanguageContext);
};
