import { lazy, Suspense } from "react"
import { Navigate, Route,BrowserRouter as Router, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import PageLayout from "./components/layout/PageLayout";
import { AuthProvider, AuthStatusMiddleware, SubdomainMiddleware } from "./authProvider";

const Home = lazy(() => import('./containers/home'));
const About = lazy(() => import('./containers/about'));
const Projects = lazy(() => import('./containers/projects'));
const Services = lazy(() => import('./containers/services'));
const Login = lazy(() => import('./containers/login'));
const Dashboard = lazy(() => import('./containers/dashboard'));
const Contact = lazy(() => import('./containers/contact'));
const Team = lazy(() => import('./containers/team'));
const Careers = lazy(() => import('./containers/careers'));
const JobDetails = lazy(() => import('./containers/jobDetails'));
const NotFound = lazy(() => import('./containers/pageNotFound'));

const AppRoutes = () => {
    return(
        <Router>
            <Suspense fallback={<>Loading</>}>
        <Routes>
            <Route
                path="/"
                element={
                    <Navigate to="/home" />
                }
            />
            <Route 
                path="/home"
                element={
                    <AppLayout>
                        <Home />
                    </AppLayout>
                }
            />
            <Route 
                path="/about"
                element={
                    <PageLayout>
                        <About />
                    </PageLayout>
                }
            />
            <Route 
                path="/projects"
                element={
                    <PageLayout>
                        <Projects />
                    </PageLayout>
                }
            />
            <Route
                path="/services"
                element={
                    <PageLayout>
                        <Services />
                    </PageLayout>
                } />
            <Route 
                path="/contact"
                element={
                    <PageLayout>
                        <Contact />
                    </PageLayout>
                }
            />
            <Route 
                path="about/team"
                element={
                    <PageLayout>
                        <Team />
                    </PageLayout>
                }
            />
            <Route 
            path="/careers"
            element={
                <PageLayout>
                    <Careers />
                </PageLayout>
            }
            />
            <Route 
            path="/careers/:id"
            element={
                <PageLayout>
                    <JobDetails />
                </PageLayout>
            }
            />
            <Route 
                path="/login"
                element={
                    <AuthProvider>
                    <SubdomainMiddleware allowedSubdomains={['admin']} >
                    <Login />
                    </SubdomainMiddleware>
                    </AuthProvider>
                } />
            <Route
                path="/dashboard"
                element={
                    <AuthProvider>
                    <SubdomainMiddleware allowedSubdomains={['admin']} >
                    <AuthStatusMiddleware requiredStatus="authenticated">
                    <Dashboard />
                    </AuthStatusMiddleware>
                    </SubdomainMiddleware>
                    </AuthProvider>
                } />

            <Route 
                path="404"
                element={
                    <PageLayout>
                        <NotFound />
                    </PageLayout>
                }
            />
            
            <Route 
                path="*"
                element={
                    <Navigate to="/404" replace />
                }
            />
        </Routes>
            </Suspense>
        </Router>
    )
}

export default AppRoutes;