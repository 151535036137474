import { useEffect, useState } from "react";
import { useLanguage } from "../../languageContext";
import { useNavigate } from "react-router-dom";

export default function Navbar() {

  const {language,translations,} = useLanguage();

  const navigate = useNavigate();

  const [showNavbar, setShowNavbar] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      let element = document.getElementById("navbar");
      let element2 = document.getElementById("icon-head");
      if (currentScrollY > 70) {
        element.classList.add("color_changed");
        element2.classList.add("remove");
      } else {
        element.classList.remove("color_changed");
        element2.classList.remove("remove");
      }
      setShowNavbar(currentScrollY <= 40 || currentScrollY < lastScrollY);
      lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  return (
    <nav
      dir={language === "ar" ? "rtl" : "ltr"}
      id="navbar"
      className={`fixed top-[3em] text-white left-0 right-0 z-50 transition-transform duration-300 bg-transparent ${
        showNavbar ? "translate-y-0" : "-translate-y-[5em]"
      }`}
    >
      <div className={`flex items-center justify-between px-4 py-4`}>
        <ul className="flex space-x-4 hidden md:flex">
          <li>
            <a href="/home" className="hover:underline hover:font-bold">
              {translations.home}
            </a>
          </li>
          <li>
            <a href="/about" className="hover:underline hover:font-bold">
              {translations.aboutUs}
            </a>
          </li>
          <li>
            <a href="/projects" className="hover:underline hover:font-bold">
              {translations.whatWeBuilt}
            </a>
          </li>
          <li>
            <a href="/services" className="hover:underline hover:font-bold">
              {translations.whatWeProvide}
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:underline hover:font-bold">
              {translations.connectToUs}
            </a>
          </li>
          <li>
            <a href="/careers" className="hover:underline hover:font-bold">
              {translations.careers}
            </a>
          </li>
        </ul>

        <div onClick={()=>navigate('/')} className="text-xl font-bold flex items-center justify-center gap-2 mr-10">
          <div id="icon-head" className="text-gray-semigray icon-font text-2xl">
            {translations.icon}
          </div>
          <img
            src="/logo512.png"
            alt={'Icon ECC Logo'}
            className="h-[2em] object-contain bg-gray-white bg-opacity-[1] rounded-sm p-1"
          />
        </div>

        {/* Mobile Hamburger Icon */}
        <button
          onClick={toggleMobileMenu}
          className="md:hidden flex items-center justify-center p-2 text-2xl text-white"
        >
          <span className="material-icons">
            {isMobileMenuOpen ? <div className="text-black">&times;</div> : <div className="text-black">&#9776;</div>}
          </span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"} bg-gray-800 text-white p-4`}>
        <ul>
          <li>
            <a href="/home" className="block hover:underline hover:font-bold py-2">
              {translations.home}
            </a>
          </li>
          <li>
            <a href="/about" className="block hover:underline hover:font-bold py-2">
              {translations.aboutUs}
            </a>
          </li>
          <li>
            <a href="/projects" className="block hover:underline hover:font-bold py-2">
              {translations.whatWeBuilt}
            </a>
          </li>
          <li>
            <a href="/services" className="block hover:underline hover:font-bold py-2">
              {translations.whatWeProvide}
            </a>
          </li>
          <li>
            <a href="/contact" className="block hover:underline hover:font-bold py-2">
              {translations.connectToUs}
            </a>
          </li>
          <li>
            <a href="/careers" className="block hover:underline hover:font-bold py-2">
              {translations.careers}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
