import { useLanguage } from "../../languageContext";
import Footer from "./footer";
import Navbar from "./navbar";

export default function AppLayout({children}){

    const {switchLanguage} = useLanguage()

    function changeLanguage(lang){
        switchLanguage(lang)
    }

    return(
        <section className="relative">
            <span className="text-sm z-[12] absolute left-4 top-3 text-gray-white flex  items-center">
                <span className="pr-3 border-r cursor-pointer hover:font-bold hover:text-gray-semigray" onClick={()=>changeLanguage('ar')}>العربية</span>
                <span className="pl-3 cursor-pointer hover:font-bold hover:text-gray-semigray" onClick={() => changeLanguage('en')}>English</span>
            </span>
            <Navbar />
        {children}
        <Footer />
        </section>
    );
}