import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Create the Auth Context
const AuthContext = createContext();

// AuthProvider to manage auth and subdomain states
export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(null);
  const [subdomain, setSubdomain] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Extract subdomain from the hostname
    const host = window.location.host;
    const currentSubdomain = host.includes('.')
      ? host.split('.')[0]
      : null; // Fallback if no subdomain exists

    setSubdomain(currentSubdomain);

    // Fetch authentication status from localStorage
    const token = localStorage.getItem('authToken');
    setAuthStatus(token ? 'authenticated' : 'unauthenticated');
  }, [location]);

  return (
    <AuthContext.Provider value={{ authStatus, subdomain }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook for consuming the AuthContext
export const useAuth = () => useContext(AuthContext);

// Middleware to restrict access based on subdomain
export const SubdomainMiddleware = ({ children, allowedSubdomains }) => {
  const { subdomain } = useAuth();
  let sub = subdomain;
  if(!sub){
    const host = window.location.host;
    const currentSubdomain = host.includes('.')
      ? host.split('.')[0]
      : null;
      sub = currentSubdomain
  }

  // Check if the current subdomain is allowed
  if (allowedSubdomains.includes(sub)) {
    return <>{children}</>;
  }else{
    window.location.href = '/404'
  }


  // return (
  //   <div className="text-center text-red-500">
  //     Access Denied: Invalid Subdomain
  //   </div>
  // );
};

// Middleware to restrict access based on auth status
export const AuthStatusMiddleware = ({ children, requiredStatus }) => {
  const { authStatus } = useAuth();

  // Check if the required authentication status matches
  if (authStatus === requiredStatus) {
    return <>{children}</>;
  }else{
    window.location.href = '/login'
  }

  // return (
  //   <div className="text-center text-red-500">
  //     Access Denied: Invalid Authentication Status
  //   </div>
  // );
};
