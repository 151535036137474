import Footer from "./footer";
import Navbar from "./normalNavbar";

export default function PageLayout({children}){

    function toggleLanguage(){
        var children = document.children;
        var i;
        for (i = 0; i < children.length; i++) {
           children[i].style.direction = "rtl";
        }
        
    }

    return(
        <section className="relative">
            <Navbar />
        {children}
        <Footer />
        </section>
    );
}